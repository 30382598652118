<a
  [routerLink]="[link()]"
  [ngClass]="{
    'button-link': true,
    primary: variety() === 'primary',
    secondary: variety() === 'secondary',
    grey: variety() === 'grey',
    stretch: width() === 'stretch',
    disabled: disabled(),
    withIcon: withIcon(),
    'narrow-padding': narrowPadding(),
  }"
  ><ng-content></ng-content
></a>
