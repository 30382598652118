import { Component, input } from '@angular/core';
import { RouterLink } from '@angular/router';

import { ROUTE_HOME } from '@settings/routes';

@Component({
  selector: 'app-logo-link',
  standalone: true,
  imports: [RouterLink],
  templateUrl: './logo-link.component.html',
  styleUrl: './logo-link.component.scss',
})
export class LogoLinkComponent {
  homeLink = ROUTE_HOME;

  fillLogo = input<string | null>(null);
  fillText = input<string | null>(null);
}
