<a [routerLink]="homeLink" class="logo-link">
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="76"
    height="41"
    viewBox="0 0 76 41"
    fill="none"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M23.5862 2.85549C28.102 0.969868 32.9416 0 37.8291 0C42.7167 0 47.5563 0.969868 52.0721 2.85549C56.5871 4.7403 60.6905 7.50316 64.1463 10.9858C67.6021 14.4685 70.3436 18.6038 72.2139 23.1539C74.085 27.7048 75.0473 32.582 75.0473 37.5076V40.5H24.939L25.188 37.2756C25.7753 29.6691 27.4772 23.2531 31.0103 18.6972C34.6776 13.9684 39.9988 11.6417 46.8185 11.6417H49.6926L49.7861 14.5365C49.887 17.6495 49.054 22.2307 46.0627 25.9249C43.1723 29.4945 38.5654 31.8679 31.9222 31.6277C31.7392 32.5402 31.5797 33.5019 31.4439 34.5152H68.9663C68.6718 31.4014 67.9185 28.3443 66.7267 25.4445C65.155 21.6199 62.8512 18.1446 59.9461 15.2178C57.0419 12.291 53.5934 9.96841 49.7983 8.38448C46.0033 6.80055 41.9358 5.98482 37.8283 5.98482C33.7209 5.98482 29.6533 6.80055 25.8583 8.38448C22.0633 9.96841 18.6148 12.2902 15.7106 15.2178C12.8063 18.1455 10.5016 21.6199 8.92993 25.4445C7.35578 29.2748 5.93864 36.3672 5.93864 40.5H0C0 35.5678 1.57496 27.699 3.4436 23.1539C5.31386 18.6029 8.0554 14.4685 11.5112 10.9858C14.967 7.50316 19.0712 4.7403 23.5862 2.85549ZM33.7656 25.6388C37.7567 25.4314 40.0696 23.8614 41.4615 22.1422C42.502 20.8575 43.1479 19.3375 43.5026 17.8831C39.9874 18.4693 37.507 20.0393 35.6896 22.3824C34.9696 23.3104 34.3278 24.3943 33.7656 25.6388Z"
      [attr.fill]="fillLogo() || '#6F9305'"
    />
  </svg>

  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="181"
    height="29"
    viewBox="0 0 181 29"
    fill="none"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M177.592 24.4769H159.394C159.394 19.7115 159.802 15.4111 161.994 12.5753C163.92 10.0822 167.062 8.78204 170.078 9.19642C170.333 11.2166 169.846 13.9997 168.289 15.9302C167.463 16.9534 165.835 18.6237 162.308 19.1055H177.592V24.4769Z"
      [attr.fill]="fillText() || '#050609'"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M150.144 8.71606V28.4201H144.438V14.5931H144.175C141.983 14.5931 140.217 16.3791 140.217 18.5754V21.9164H134.507V18.5754C134.507 16.3791 132.74 14.5931 130.548 14.5931H130.285V28.4201H124.574V8.7171H130.444C132.513 8.72228 134.435 9.37494 136.018 10.4824H136.02C136.499 10.8212 136.948 11.2013 137.359 11.6188C137.771 11.2013 138.221 10.8201 138.706 10.4824C140.288 9.37494 142.211 8.72228 144.282 8.72228H144.283L150.144 8.71606Z"
      [attr.fill]="fillText() || '#050609'"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M113.313 8.71704C107.924 8.71704 103.542 13.1251 103.542 18.5712C103.542 24.0172 107.925 28.4201 113.313 28.4201C118.701 28.4201 123.089 24.012 123.089 18.5712C123.089 13.1303 118.707 8.71704 113.313 8.71704ZM113.42 22.5534C111.229 22.5534 109.463 20.7622 109.463 18.5712C109.463 16.3801 111.23 14.5827 113.42 14.5827C115.61 14.5827 117.379 16.3739 117.379 18.5712C117.379 20.7684 115.612 22.5534 113.42 22.5534Z"
      [attr.fill]="fillText() || '#050609'"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M95.1203 8.72222C95.0426 8.71704 94.971 8.71704 94.9332 8.71704H87.2479C83.2484 8.71704 79.9594 11.8281 79.6516 15.7906C79.6291 16.0061 79.624 16.2123 79.624 16.4029V28.4201H85.3351V23.838C85.9464 23.9986 86.5865 24.0835 87.2479 24.0835H87.2561C87.2919 24.0835 87.3584 24.0835 87.431 24.0794L94.9332 24.0835C94.971 24.0835 95.0426 24.0835 95.1203 24.0784C99.2375 23.9768 102.55 20.583 102.55 16.4029C102.55 12.2228 99.2385 8.82478 95.1203 8.72222ZM96.9269 16.4474C96.9269 17.2368 96.4208 17.934 95.7143 18.1868L95.7061 18.1899L95.698 18.193C95.5088 18.2655 95.2941 18.3059 95.0763 18.3059H87.3993C87.0731 18.3059 86.7756 18.2231 86.5272 18.0811L86.518 18.076L86.5098 18.0708C85.9342 17.759 85.5487 17.1467 85.5487 16.4474C85.5487 15.7481 85.9178 15.1556 86.4812 14.8344H86.4832L86.4852 14.8313C86.7541 14.6759 87.0619 14.5879 87.3993 14.5879H95.0763C95.3095 14.5879 95.5303 14.6314 95.7327 14.7122L95.7378 14.7143L95.7429 14.7163C96.4382 14.9815 96.9269 15.6559 96.9269 16.4474Z"
      [attr.fill]="fillText() || '#050609'"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M78.115 25.075V28.4201H72.404V25.0801C72.404 22.8828 70.6373 21.0968 68.4453 21.0968C67.1203 21.0968 63.9212 21.0782 61.8785 21.0637V28.4201H56.1675V8.71704H61.8785V15.0447C62.2813 15.0509 62.7148 15.0654 63.1739 15.0831L65.0898 8.71704H70.8694L68.9074 15.2374C74.0367 15.5347 78.115 19.8216 78.115 25.075Z"
      [attr.fill]="fillText() || '#050609'"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M53.6614 22.4964C51.7843 22.1742 50.3653 20.5333 50.3653 18.5712C50.3653 16.1978 49.53 14.017 48.1426 12.3191C46.698 10.5372 44.6409 9.27854 42.2915 8.86622H42.2905C41.7415 8.77091 41.174 8.71704 40.5943 8.71704C35.1992 8.71704 30.8224 13.1261 30.8224 18.5712C30.8224 24.0162 35.2002 28.4201 40.5943 28.4201C43.2985 28.4201 45.7451 27.3116 47.5118 25.5215C49.0617 27.0909 51.1372 28.1373 53.4447 28.3673L54.3424 28.4563V22.6135L53.6614 22.4964ZM40.5044 14.5879C40.5698 14.5848 40.6352 14.5827 40.7007 14.5827C41.1229 14.5827 41.5268 14.648 41.904 14.7692H41.905C43.504 15.283 44.6593 16.7955 44.6593 18.5712C44.6593 20.7622 42.8916 22.5534 40.7007 22.5534C38.5097 22.5534 36.743 20.7622 36.743 18.5712C36.743 18.0822 36.8309 17.6139 36.9915 17.1809C37.5292 15.7264 38.888 14.6666 40.5044 14.5879Z"
      [attr.fill]="fillText() || '#050609'"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M29.5467 18.5659C29.5467 23.9736 25.2281 28.3609 19.8862 28.42H10.3258C4.93175 28.42 0.549805 24.0119 0.549805 18.5659V17.7371H6.46941V18.5659C6.46941 20.73 8.19213 22.5005 10.335 22.5533H19.878C22.07 22.5533 23.8407 20.7663 23.8407 18.5711C23.8407 16.3758 22.071 14.5878 19.879 14.5878H19.878L16.1729 14.594V8.71694H19.9506C20.4567 8.71694 20.8615 8.30463 20.8615 7.79908C20.8615 7.29353 20.4751 6.90297 19.9802 6.88743L19.9588 6.89157H16.0195C15.539 6.92369 15.1699 7.32046 15.1699 7.80426V8.63303H9.28404V7.80426C9.28404 4.05613 12.2878 1.03215 15.995 1.02075H19.878C23.5913 1.02075 26.6063 4.05095 26.6063 7.79908C26.6063 8.90549 26.3425 9.94975 25.8784 10.8707C28.1123 12.6743 29.5467 15.4476 29.5467 18.5659Z"
      [attr.fill]="fillText() || '#050609'"
    />
    <path
      d="M151.964 15.698C151.964 7.57397 158.465 0.987305 166.482 0.987305C174.5 0.987305 181 7.57397 181 15.698V28.4404H175.07V15.698C175.07 10.8922 171.225 6.99591 166.482 6.99591C161.739 6.99591 157.894 10.8922 157.894 15.698V28.4404H151.964V15.698Z"
      [attr.fill]="fillText() || '#050609'"
    />
  </svg>
</a>
